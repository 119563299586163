import * as axiosHelper from "../api_helper";

export const fetchWithdrawalGatewaysAPI = async () => {
  const gateways =  await axiosHelper.get("/fxtransactions/withdrawals-gateways");
  if (!gateways.status){
    throw new Error(gateways.message);
  }
  return gateways;
};

export const addWithdrawalAPI = async ({ payload }) => {
  const result = await axiosHelper.post("/fxtransactions/withdrawals", payload);
  if (!result.status){
    throw new Error(result.message);
  }
  return result;
};
