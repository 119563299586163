module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  SOCKET_URL: process.env.REACT_APP_API_CP_DOMAIN,
  BACKEND_CP_URL: process.env.REACT_APP_SOCKET_URL,
  MAX_UNAPPROVED_ACCOUNTS: 3,
  enableFX: process.env.REACT_APP_ENABLE_FX === "true" || true,
  enableCrypto: process.env.REACT_APP_ENABLE_CRYPTO === "true" || false,
  enableCryptoWallets: process.env.REACT_APP_ENABLE_CRYPTO_WALLETS === "true" || false,
  defaultPortal: process.env.REACT_APP_DEFAULT_PORTAL || "FOREX",
  companyCryptoDeposit: process.env.REACT_APP_COMPANY_CRYPTO_DEPOSIT === "true" || false,
  COMPANY_WALLETS: [
    {
      name:"USDT",
      networks: [
        {
          name: "Tron - TRC20",
          address: "TTaXPSpAsRPBz25qbMMQcGpsjA5wzCNdi1",
          tag: "TRC20",
        }
      ],
    }
  ],
  ENABLE_DARK_MODE: false,
  ENABLE_GLOBAL_SEARCH: false,
  LEVERAGES: [1, 50, 100, 200, 300, 400],
  PLATFORMS: [
    {
      name: "Desktop Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.metatrader5.com/i/main/metatrader-5-windows.jpg",
      isLocalImg: false,
      downloadLink: "https://exiniti-my.sharepoint.com/personal/rashad_exinitic_com/_layouts/15/download.aspx?SourceUrl=%2Fpersonal%2Frashad%5Fexinitic%5Fcom%2FDocuments%2FCracked%20MT5%2FCrystal4x%2FCrystal%2Emt5%2Eterminal%2Esetup%2Emsi",
    },
    {
      name: "Android Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://i.ytimg.com/vi/MTLbJ5lSW9M/maxresdefault.jpg",
      isLocalImg: false,
      downloadLink: "https://exiniti-my.sharepoint.com/personal/rashad_exinitic_com/_layouts/15/download.aspx?SourceUrl=%2Fpersonal%2Frashad%5Fexinitic%5Fcom%2FDocuments%2FCracked%20MT5%2FCrystal4x%2Fmetatrader5%20%281%29%2Eapk",
    },
    // {
    //   name: "IOS Trader - MT5",
    //   logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
    //   image: "https://www.ifcmarkets.ae/images/front/platforms/MT4-MT5-mobile-ios.png",
    //   isLocalImg: false,
    //   downloadLink: "https://download.metatrader.com/cdn/mobile/mt5/ios?server=MarketWinner-Live",
    // },
  ],
};
