import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./webtrader.scss";

const app_config = {
  clientName: "Terra Fx Capital",
  sidebarTitle: "Terra Fx Capital Client Portal",
  webTraderServer: "MarketWinner-Live",
  webTraderVersion: 5,
  webTraderUtmSource: "www.marketwinner.com.kw",
  contactUs: "#",
  linkDesktop:
    "https://download.metatrader.com/cdn/web/market.winner.commission/mt5/marketwinner5setup.exe",
  linkAndroid: "#",
  linkIos: "#",
};

const WebTrader = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [height, setHeight] = React.useState(700);
  useEffect(() => {
    const { height } = getWindowDimensions();
    setHeight(height - height * 0.1);
  }, []);

  return (
    <>
      <div id="webterminal" className={"web-terminal"} style={{
        height,
        zIndex: 3,
        position: "relative",
        marginTop: "10%",
      }}>
        <iframe style={{
          height: "100%",
          width: "100%",
        }} src={"https://crystalfxmt5.tgsmarkets.net"} allowfullscreen="allowfullscreen"></iframe>
      </div>
      <Helmet>
        {/* <script src='https://trade.mql5.com/trade/widget.js'></script> */}

        <script type="text/javascript">
          {/* {`
            setTimeout(() => {
              new MetaTraderWebTerminal( "webterminal", {
                version: ${app_config.webTraderVersion},
                servers: ["MarketWinner-Live", "MarketWinner-Server"],
                server: "${app_config.webTraderServer}",
                utmSource: "${app_config.webTraderUtmSource}",
                startMode: "real",
                language: "en",
                colorScheme: "black_on_white"
            } );
          }, 1000);
          `} */}
        </script>
      </Helmet>
    </>
  );
};

export default WebTrader;
