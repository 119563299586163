import logo from "assets/images/brands/Crystal4xLogo.png";
import DevelopedByLogo from "assets/images/brands/DevelopedByLogo.png";


export const clientName = "Crystal";
export const developedBy = "Crystal";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL ;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "Crystal";
export const companyWebsite = "";
export const privacyPolicyLink = "";
export const clientAgreementLink = "";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
export const DevelopedBy = "Exinitic";
export const DevelopedLogo = DevelopedByLogo;
export const DelevepedAltImg = "Exinitic Logo";
