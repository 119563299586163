import { CUSTOMER_SUB_PORTALS } from "common/constants";
import dashboardSvg from "assets/img/icons/home.svg";
import accountsSvg from "assets/images/sidebar/users.svg";
import dotSvg from "assets/img/dot.svg";
import walletSvg from "assets/img/wallet.svg";
import depositSvg from "assets/img/icons/money-bag.svg";
import withdrawSvg from "assets/images/sidebar/withdraw.svg";
import transferSvg from "assets/images/sidebar/transfer.svg";
import platformsSvg from "assets/img/icons/platforms.svg";
import webTraderSvg from "assets/img/icons/earth.svg";
import ibWithdrawSvg from "assets/img/icons/deposit.svg";
import ibTransferSvg from "assets/img/icons/swap.svg";
import partnershipSvg from "assets/img/partnership.svg";
import reportSvg from "assets/img/icons/reports.svg";
import profileSvg from "assets/img/icons/profile.svg";

export default (portal, profile) => {
  let menu = [
    {
      title: "Dashboard",
      icon: dashboardSvg,
      link: "/dashboard",
    }
  ];
  switch (portal) {
    case CUSTOMER_SUB_PORTALS.DEMO:
    case CUSTOMER_SUB_PORTALS.LIVE:
      // Doing this way because permissions based menu
      if (profile?.fx?.isClient || profile?.fx?.isDemo) {
        // Account details
        menu.push(
          {
            title: "Accounts",
            icon: accountsSvg,
            link: "#",
            hasSubMenu: true,
            subMenu: [
              {
                title: "Live Accounts",
                icon: dotSvg,
                link: "/accounts/live",
                hasSubMenu: false,
                subMenu: [],
              },
              {
                title: "Demo Accounts",
                icon: dotSvg,
                link: "/accounts/demo",
                hasSubMenu: false,
                subMenu: [],
              },
              {
                title: "Change Password",
                icon: dotSvg,
                link: "/accounts/password",
                hasSubMenu: false,
                subMenu: [],
              },
            ],
          }
        );
        menu.push(
          {
            title: "Wallets",
            icon: walletSvg,
            link: "/wallet",
            hasSubMenu: false,
            subMenu: [],
          },
          {
            title: "Deposit",
            icon: depositSvg,
            onClick: "selectDepositMethodModal",
            hasSubMenu: false,
            subMenu: [],
          },
          {
            title: "Withdraw",
            icon: withdrawSvg,
            onClick: "selectWithdrawalMethodModal",
            hasSubMenu: false,
            subMenu: [],
          },
          {
            title: "Internal Transfer",
            icon: transferSvg,
            onClick: "Transfer",
            hasSubMenu: false,
            subMenu: [],
          },
          {
            title: "Platforms",
            icon: platformsSvg,
            link: "/platforms",
            hasSubMenu: false,
            subMenu: [],
          },
          {
            title: "Web Trader",
            icon: webTraderSvg,
            link: "/web-trader",
            hasSubMenu: false,
            subMenu: [],
          },
        );
      }
      if (!profile?.fx?.isIb) {
        menu.push({
          title: "Partnership",
          icon: partnershipSvg,
          link: "/request-partnership",
          hasSubMenu: false,
          subMenu: [],
        }
        );
      }
      break;
    case CUSTOMER_SUB_PORTALS.IB:
      if (profile?.fx?.isIb) {
        menu.push(
          {
            title: "Wallets",
            icon: walletSvg,
            link: "/wallet",
            hasSubMenu: false,
            subMenu: [],
          },
          {
            title: "Withdraw",
            icon: ibWithdrawSvg,
            link: "/withdraw",
            hasSubMenu: false,
            subMenu: [],
          },
          {
            title: "Internal Transfer",
            icon: ibTransferSvg,
            link: "/ib/transfer",
            hasSubMenu: false,
            subMenu: [],
          },
          {
            title: "Partnership",
            icon: partnershipSvg,
            link: null,
            hasSubMenu: true,
            subMenu: [
              {
                title: "My Live Clients",
                icon: dotSvg,
                link: "/ib/clients/live",
                hasSubMenu: false,
                subMenu: [],
              },
              {
                title: "My Demo Clients",
                icon: dotSvg,
                link: "/ib/clients/demo",
                hasSubMenu: false,
                subMenu: [],
              },
              {
                title: "Partnership",
                icon: dotSvg,
                link: "/partnership",
                hasSubMenu: false,
                subMenu: [],
              },
              {
                title: "Referrals",
                icon: dotSvg,
                link: "/referrals",
                hasSubMenu: false,
                subMenu: [],
              },
              {
                title: "Statement",
                icon: dotSvg,
                link: "/statement",
                hasSubMenu: false,
                subMenu: [],
              },
            ],
          });
        if (profile?.fx?.liveAcc?.length === 0) {
          menu.push({
            title: "Trading Account",
            icon: platformsSvg,
            link: "/trading-account",
            hasSubMenu: false,
            subMenu: [],
          });
        }
      }
      break;
    default:
  }
  menu.push(
    {
      title: "Reports",
      icon: reportSvg,
      link: "/reports",
      hasSubMenu: false,
      subMenu: [],
    },
    {
      title: "My Profile",
      icon: profileSvg,
      link: "#",
      hasSubMenu: true,
      subMenu: [
        {
          title: "User Profile",
          icon: dotSvg,
          link: "/profile",
          hasSubMenu: false,
          subMenu: [],
        },
        {
          title: "My Documents",
          icon: dotSvg,
          link: "/documents",
          hasSubMenu: false,
          subMenu: [],
        },
        {
          title: "My Applications",
          icon: dotSvg,
          link: "/application",
          hasSubMenu: false,
          subMenu: [],
        },
        {
          title: "Bank Accounts",
          icon: dotSvg,
          link: "/bank-accounts",
          hasSubMenu: false,
          subMenu: [],
        },
        {
          title: "Activities",
          icon: dotSvg,
          link: "/activites",
          hasSubMenu: false,
          subMenu: [],
        },
        {
          title: "Notifications",
          icon: dotSvg,
          link: "/notifications",
          hasSubMenu: false,
          subMenu: [],
        },
        {
          title: "2FA",
          icon: dotSvg,
          link: "/two-fa",
          hasSubMenu: false,
          subMenu: [],
        },
      ],
    });
  return menu;
};