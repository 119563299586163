import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//import drawer
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import RightSidebar from "../CommonForBoth/RightSidebar";
import LightDark from "../CommonForBoth/Menus/LightDark";

// import images

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changelayoutMode
} from "../../store/actions";
import PortalEntryPoint from "./PortalEntryPoint";
import { PORTALS } from "common/constants";
import {
  enableFX,
  enableCrypto,
  ENABLE_DARK_MODE,
  ENABLE_GLOBAL_SEARCH
} from "config";
import useWindowDimensions from "hooks/useWindowDimensions";

const Header = props => {
  const { onChangeLayoutMode, onChangePortal } = props;
  const [isClick, setClick] = useState(true);
  const [position] = useState();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const onDrawerClose = () => {
    setOpen(false);
  };
  const {
    width
  } = useWindowDimensions();

  useEffect(() => {
    if (width < 993) {
      dispatch(changeSidebarType("sm", isMobile));
    } else {
      dispatch(changeSidebarType("lg", isMobile));
    }
  }, [width]);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    let body = document.body;
    body.classList.toggle("sidebar-enable");
    body.classList.toggle("sidebar-hidden");
    setClick(!isClick);
  }

  return (
    <React.Fragment>
      <PortalEntryPoint />
      <header id="page-topbar">
        <div className="navbar-header shadow-sm"
          style={{
            padding: "0",
          }}
        >
          <div className="d-flex justify-content-between">
            <button
              onClick={() => {
                tToggle();
              }}
              type="button" className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn">
              {props.layoutMode === "dark" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                <path d="M22 12H3" stroke="#ffffff"></path>
                <g stroke="#ffffff">
                  <path d="M22 4H13"></path>
                  <path opacity=".301" d="M22 20H13"></path>
                </g>
                <path d="M7 7l-5 5 5 5" stroke="#ffffff"></path>
              </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
                <path d="M22 12H3" stroke="#11142d"></path>
                <g stroke="#808191">
                  <path d="M22 4H13"></path>
                  <path opacity=".301" d="M22 20H13"></path>
                </g>
                <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
              </svg>}
            </button>
            {
              ENABLE_GLOBAL_SEARCH &&
              (
                <form className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <input type="text" className="form-control" placeholder="Search..." />
                    <button className="btn fs-4 d-flex align-items-center" type="button">
                      <i className="bx bx-search-alt-2 align-middle" />
                    </button>
                  </div>
                </form>
              )
            }
          </div>
          <div className="d-flex">
            {
              enableFX && enableCrypto && (
                <>
                  <div className="my-auto me-2">
                    {props.t("Forex")}
                  </div>
                  <div className="form-check form-switch form-switch-md my-auto">
                    <input type="checkbox" className="form-check-input" id="customSwitchsizemd" checked={props.portal === PORTALS.CRYPTO ? true : false} onChange={(e) => {
                      history.replace("/dashboard");
                      onChangePortal(e.target.checked);
                    }} />
                  </div>
                  <div className="my-auto">
                    {props.t("Crypto")}
                  </div>
                </>
              )
            }

            <LanguageDropdown />

            {/* light / dark mode */}
            {ENABLE_DARK_MODE ? <LightDark layoutMode={props["layoutMode"]} onChangeLayoutMode={onChangeLayoutMode} /> : null}
            <div className="m-auto" style={{ cursor:"pointer" }} onClick={()=>{
              window.open("https://api.whatsapp.com/message/TB24TQOQT4HOL1?autoload=1&app_absent=0");
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z"/></svg>
            </div>
            <NotificationDropdown />
            <ProfileMenu />

          </div>
        </div>
      </header>
      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} onChangeLayoutMode={onChangeLayoutMode} />
      </ReactDrawer>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  changelayoutMode: PropTypes.func,
  layoutMode: PropTypes.any,
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    layoutMode,
    portal
  } = state.Layout;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    layoutMode,
    portal
  };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  changelayoutMode,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
